import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface dopublishParam {
    publishTime?:any
    versionId?:any
}

export interface removeVersionParam {
    id?:any
}

export interface updateVersionParam {
    publishTime?:any
    appId?:any
    resourcesPage?:any
    id?:any
    versionLevel?:any
    resourcesUrl?:any
    versionCode?:any
}

export interface addVersionParam {
    publishTime?:any
    appId?:any
    resourcesPage?:any
    versionLevel?:any
    resourcesUrl?:any
    versionCode?:any
}

export interface loadLatestVersionParam {
    version?:any
}

export interface loadVersionPageParam {
    current?:any
    size?:any
    appId?:any
    publish?:any
    startTime?:any
    endTime?:any
    keyword?:any
}
// -------接口列------

/**
 * [后台]发布版本
 * @notes 
 */
export function dopublish(param:dopublishParam) {
    return post('/system/version/dopublish', param);
}

/**
 * [公共]获取最新版本信息
 * @notes 
 */
export function loadLatestVersion(param:loadLatestVersionParam) {
    return get('/system/version/loadLatestVersion', param);
}

/**
 * [后台]删除版本信息
 * @notes 
 */
export function removeVersion(param:removeVersionParam) {
    return get('/system/version/removeVersion', param);
}

/**
 * [后台]编辑版本信息
 * @notes 
 */
export function updateVersion(param:updateVersionParam) {
    return post('/system/version/updateVersion', param);
}

/**
 * [后台]获取所有用户反馈信息
 * @notes 
 */
export function loadVersionPage(param:loadVersionPageParam) {
    return get('/system/version/loadVersionPage', param);
}

/**
 * [后台]添加版本信息
 * @notes 
 */
export function addVersion(param:addVersionParam) {
    return post('/system/version/addVersion', param);
}

