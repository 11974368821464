
import { adjustWallet } from '@/api/user/wallet';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'AdjustWallet'
})
export default class AdjustWallet extends Vue {
    @Prop({ required: true }) dialogUpdateVisible!: boolean;
    @Prop({ required: true }) readonly wallet!: any;
    
    get dialogVisible(){
      return this.dialogUpdateVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogUpdateVisible', val)
    }
    
    accountInfo: any = {
        amount: 0,
        money: 0,
        userId: null
    }

    formParam = {
        userId: null,
        accountType: 'COIN',
        inOut: 1,
        amount: null,
        message: null
    }

    @Emit("getList")
    getList(){}

    handleUpdate(){
        adjustWallet(this.formParam).then(res => {
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
            this.dialogVisible = false
            this.getList();
        })
    }

    @Watch("wallet")
    infoParamsChange(){
        this.accountInfo = this.wallet
        this.formParam.userId = this.wallet.userId
        this.formParam.accountType = 'COIN'
        this.formParam.inOut = 1
        this.formParam.amount = null
        this.formParam.message = null
    }
}
