
import { Component,Vue } from 'vue-property-decorator'
import JtPagination from '@/components/JtPagination/index.vue'
import { loadEnabled } from '@/api/system/appConfig'
import JtDatePicker from '@/components/JtDatePicker/index.vue'
import Publish from './components/Publish.vue'
import { loadVersionPage, removeVersion } from '@/api/system/version'
import ChangeVersion from './components/ChangeVersion.vue'

@Component({
    name: 'VersionConfig',
    components: {
      Publish,
      ChangeVersion,
      JtDatePicker,
      JtPagination
    }
})
export default class extends Vue {
  listLoading = true
  total = 0
  list = []
  appOptions:Array<{
    appId:any
    platform:any
  }> = []
  listQuery = {
    current: 1,
    size: 10,
    appId: null,
    publish: null,
    startTime: null,
    endTime: null
  }
  dialogVisibleChange = false
  isEdit = false
  infoParams:any = {}
  dialogPublishVisible = false

  mounted() {
      this.getList()
      this.getAppOptions()
  }
  //初始加载数据
  handleFilter() {
    this.getList()
  }

  //获取所有可用应用数据
  getAppOptions(){
    loadEnabled().then(res=>{
        this.appOptions = res.data
    })
  }

  //加载api数据
  getList(){
    this.listLoading = true
    loadVersionPage(this.listQuery).then(response => {
        this.list = response.data.rows
        this.total = response.data.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
    })
  }

  handleRemove(row:any){
    this.$confirm('此操作将删除当前配置, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      removeVersion({ id: row.id }).then(response => {
          this.handleFilter()
          this.$message({
              type: 'success',
              message: '操作成功!'
          });
      }).catch(()=>{})
    }).catch(() => {
      this.$message({
            type: 'info',
            message: '已取消当前操作'
      });      
    });
  }

  //添加
  handleAdd(){
    this.isEdit = false
    this.infoParams = {}
    this.dialogVisibleChange = true
  }

  //编辑
  handleEdit(row:any){
    this.isEdit = true
    this.infoParams = row
    this.dialogVisibleChange = true
  }

  //版本发布
  publish(row:any){
    this.infoParams = row
    this.dialogPublishVisible = true
  }
}
