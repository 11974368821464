
  import { loadWithdrawApplyDatas } from '@/api/user/withdrawApply';
  import JtDatePicker from '@/components/JtDatePicker/index.vue';
  import JtPagination from '@/components/JtPagination/index.vue';
  import { Component,Vue, Watch } from 'vue-property-decorator'
  import ExamineApply from './components/ExamineApply.vue';
  import RequestApply from './components/RequestApply.vue';
  
  @Component({
      name: 'WithdrawLogs',
      components: {
        JtPagination,
        JtDatePicker,
        ExamineApply,
        RequestApply
      }
  })
  export default class extends Vue {
    examineInfoVisible = false
    isEdit = false //是否是用于操作
    requestInfoVisible = false
    listLoading = true
    total = 0
    list = []
    listQuery = {
      searchType: null,
      keyword: null,
      applyNo: null,
      startTime: null,
      status: null,
      dealStatus: null,
      endTime: null,
      current: 1,
      size: 10
    }

    applyNo:any = null

    infoParams = {}
  
    mounted() {
        this.getList()
    }
    //初始加载数据
    handleFilter() {
      this.listQuery.current = 1
      this.getList()
    }
  
    //加载api数据
    getList(){
      this.listLoading = true
      loadWithdrawApplyDatas(this.listQuery).then(response => {
          this.list = response.data.rows
          this.total = response.data.total
          // Just to simulate the time of the request
          setTimeout(() => {
            this.listLoading = false
          }, 500)
        }).catch(()=>{
          this.listLoading = false
        })
    }
  
    resetSearch(){
      this.listQuery.searchType = null
      this.listQuery.keyword = null
      this.listQuery.applyNo = null
      this.listQuery.startTime = null
      this.listQuery.endTime = null
      this.listQuery.status = null
      this.listQuery.dealStatus = null
    }
    //审核
    examineTableRow(row:any){
      this.isEdit = true
      this.infoParams = row
      this.examineInfoVisible = true
    }
    //审核详情
    handleExamineInfo(row:any){
      this.isEdit = false
      this.infoParams = row
      this.examineInfoVisible = true
    }
    //打款日志
    handlePlayMoneyLogs(row:any){
      this.applyNo = row.applyNo
      this.requestInfoVisible = true
    }
  }
  