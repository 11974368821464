
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { dealRealnameAuthAudit } from "@/api/user/userAuth";

@Component({
  name: 'UserAuthOperate'
})
export default class UserAuthOperate extends Vue {
  @Prop({
    required: true,
    default: false
  }) dialogOperateVisible!: boolean;
  @Prop({ required: true }) readonly userAudit!: any;
  formParam: {
    orderNo: string
    status: boolean
    notes: string
  } = {
    orderNo: '',
    status: false,
    notes: ''
  }
  previewList:Array<string> = []
  get dialogVisible() {
    return this.dialogOperateVisible;
  }
  set dialogVisible(val) {
    this.$emit('update:dialogOperateVisible', val)
  }
  @Watch("userAudit")
  infoParamsChange() {
    this.previewList = [this.userAudit.cardForward, this.userAudit.cardBackend, this.userAudit.cardWithFace]
    this.formParam = {
      orderNo: this.userAudit.orderNo,
      status: false,
      notes: ''
    }
  }
  handleUpdate() {
    dealRealnameAuthAudit(this.formParam).then(res=>{
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.dialogVisible = false
      this.finish()
    })
  }
  @Emit("finish")
  finish(){}
}
