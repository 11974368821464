
import { addShare, editShare } from '@/api/user/share';
import { cloneDeep } from '@/utils/common';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'ChangeShare'
})
export default class ChangeShare extends Vue {
    @Prop({ required: true, default: false }) dialogUpdateVisible!: boolean;
    @Prop({ required: true, default: false }) readonly isEdit!:boolean;
    @Prop({ 
      required: false, 
      default: () => {
        return {
            id: null,
            appId: null,
            title: null,
            description: null,
            webpageUrl: null,
            scene: null
        }
      }
    }) readonly infoParams!: {
            id: any
            appId: any
            title: any
            description: any
            webpageUrl: any
            scene: any
        };
    @Prop({ required: true }) 
    readonly appOptions!: Array<{
        appId:any
        platform:any
    }>;

    get dialogVisible(){
      return this.dialogUpdateVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogUpdateVisible', val)
    }
     
    formParam:{
      appId: any
      title: any
      description: any
      webpageUrl: any
      scene: any
      rewardType: any
      rewardNumber: any
    } = {
      appId: null,
      title: null,
      description: null,
      webpageUrl: null,
      scene: null,
      rewardType: '',
      rewardNumber: ''
    }

    get infoTitle(){
        if(this.isEdit){
            return "编辑分享配置-【" + this.formParam.title + "】";
        }else{
            return "添加分享配置";
        }
    }

    @Emit("getList")
    getList(){}

    handleUpdate(){
        if(this.isEdit){
            editShare(this.formParam).then(res => {
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.dialogVisible = false
                this.getList();
            })
        }else{
            addShare(this.formParam).then(res=>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.dialogVisible = false
                this.getList();
            })
        }
    }

    @Watch("infoParams")
    infoParamsChange(){
        this.formParam = cloneDeep(this.infoParams)
    }
}
