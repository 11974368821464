
import { loadUserWallets } from '@/api/user/wallet';
import JtDatePicker from '@/components/JtDatePicker/index.vue';
import JtPagination from '@/components/JtPagination/index.vue';
import { Component,Vue, Watch } from 'vue-property-decorator'
import AdjustWallet from './components/AdjustWallet.vue';
  

@Component({
    name: 'Wallet',
    components: {
      JtPagination,
      JtDatePicker,
      AdjustWallet
    }
})
export default class extends Vue {
  dialogUpdateVisible = false
  walletRow = null
  listLoading = true
  total = 0
  list = []
  listQuery = {
    searchType: null,
    parentId: null,
    accountType: null,
    amountMin: null,
    amountMax: null,
    startTime: '',
    endTime: '',
    keyword: null,
    current: 1,
    size: 10
  }

  mounted() {
      this.getList()
  }
  //初始加载数据
  handleFilter() {
    this.listQuery.current = 1
    this.getList()
  }

  //加载api数据
  getList(){
    this.listLoading = true
    loadUserWallets(this.listQuery).then(response => {
        this.list = response.data.rows
        this.total = response.data.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      }).catch(()=>{
        this.listLoading = false
      })
  }

  resetSearch(){
    this.listQuery.parentId = null
    this.listQuery.startTime = ''
    this.listQuery.keyword = null
    this.listQuery.endTime = ''
    this.listQuery.searchType = null
    this.listQuery.accountType = null
    this.listQuery.amountMin = null
    this.listQuery.amountMax = null
  }

  handleAdjustWallet(row:any){
    this.walletRow = row
    this.dialogUpdateVisible = true
  }
}
