import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface loadUserReportParam {
    current?:any
    size?:any
    keyword?:any
}

export interface removeReportParam {
    id?:any
}

export interface loadReportPageParam {
    reportType?:any
    current?:any
    size?:any
    appId?:any
    startTime?:any
    endTime?:any
    keyword?:any
    status?:any
}

export interface submitReportParam {
    reportType?:any
    appVersion?:any
    images?:any
    phoneModel?:any
    question?:any
    systemVersion?:any
}

export interface userReportInfoParam {
    id?:any
}

export interface infoParam {
    id?:any
}
// -------接口列------

/**
 * [APP]加载用户反馈信息
 * @notes 
 */
export function loadUserReport(param:loadUserReportParam) {
    return get('/system/report/loadUserReport', param);
}

/**
 * [APP]用户提交反馈信息
 * @notes 
 */
export function submitReport(param:submitReportParam) {
    return post('/system/report/submitReport', param);
}

/**
 * [后台]获取所有用户反馈信息
 * @notes 
 */
export function loadReportPage(param:loadReportPageParam) {
    return get('/system/report/loadPage', param);
}

/**
 * [后台]加载反馈类型枚举信息
 * @notes 
 */
export function loadReportType() {
    return get('/system/report/loadReportType');
}

/**
 * [后台]删除反馈信息
 * @notes 
 */
export function removeReport(param:removeReportParam) {
    return get('/system/report/removeReport', param);
}

/**
 * [APP]获取用户反馈信息详情
 * @notes 
 */
export function userReportInfo(param:userReportInfoParam) {
    return get('/system/report/userReportInfo', param);
}

/**
 * [后台]某一用户反馈信息
 * @notes 
 */
export function info(param:infoParam) {
    return get('/system/report/info', param);
}

