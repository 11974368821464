
import { getWalletByUserId } from '@/api/user/wallet';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'UserInfo'
})
export default class UserInfo extends Vue {
    @Prop({ required: true, default: false }) dialogUserInfoVisible!: boolean;
    @Prop({ required: true }) readonly user!: any;
    
    wallet: any = {}

    get dialogVisible(){
      return this.dialogUserInfoVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogUserInfoVisible', val)
    }

    @Watch("user")
    infoParamsChange(){
        getWalletByUserId({ userId: this.user.id }).then(res=>{
            this.wallet = res.data
        })
    }
}
