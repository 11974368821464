
import { loadAppDividendConfig, updateAppDividendConfig } from '@/api/system/sysConfig'
import { Component,Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'DividendSet'
})
export default class extends Vue {
    formParam:{
        dividendEnable: boolean
        coinMoneyRate: number
        dividendWay: number
        dividendLevel: number
        dividendLevelConfig: Array<{
            rate:number
            level:number
        }>
    } = {
        dividendEnable: false,
        coinMoneyRate: 10000,
        dividendWay: 0,
        dividendLevel: 1,
        dividendLevelConfig: [{
            rate: 1,
            level: 1
        }]
    }
    mounted(){
        loadAppDividendConfig().then(res=>{
            this.formParam = res.data
            if (!this.formParam.dividendLevelConfig) {
              this.formParam.dividendLevelConfig = [{ rate: 1, level: 1 }]
            }
        })
    }
    handleUpdate(){
        updateAppDividendConfig(this.formParam).then(res=>{
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
        })
    }
    //分佣方式变更时间
    @Watch("formParam.dividendLevel")
    handleDividendLevelChange(dividendWay:number){
        if(dividendWay === 0) {
            this.formParam.dividendLevelConfig = [{
                rate: 0,
                level: 1
            }];
        }else {
            if(this.formParam.dividendLevel < this.formParam.dividendLevelConfig.length){
                this.formParam.dividendLevelConfig = this.formParam.dividendLevelConfig.slice(0, this.formParam.dividendLevel)
            }
            if(this.formParam.dividendLevel > this.formParam.dividendLevelConfig.length){
                for (let index = 0; index < this.formParam.dividendLevel - this.formParam.dividendLevelConfig.length; index++) {
                    this.formParam.dividendLevelConfig.push({
                        rate: 0,
                        level: this.formParam.dividendLevelConfig.length + 1
                    });
                }
            }
        }
    }
    //分佣方式变更时间
    @Watch("formParam.dividendWay")
    handleDividendWayChange(way:number){
        if (way === 0) {
            this.formParam.dividendLevel = 1
        }
    }
}
