import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface addShareParam {
    createTime?:any
    appId?:any
    description?:any
    updateTime?:any
    id?:any
    title?:any
    webpageUrl?:any
    scene?:any
}

export interface deleteShareParam {
    id?:any
}

export interface loadPageParam {
    current?:any
    size?:any
    enable?:any
    appId?:any
    keyword?:any
}

export interface enableShareParam {
    id?:any
}

export interface editShareParam {
    createTime?:any
    appId?:any
    description?:any
    updateTime?:any
    id?:any
    title?:any
    webpageUrl?:any
    scene?:any
    rewardType?: any
    rewardNumber?: any
}
// -------接口列------

/**
 * [后台]分享管理
 * @notes 
 */
export function loadPage(param:loadPageParam) {
    return get('/user/share/loadPage', param);
}

/**
 * [后台]启用配置
 * @notes 
 */
export function enableShare(param:enableShareParam) {
    return get('/user/share/enableShare', param);
}

/**
 * [后台]编辑分享配置
 * @notes 
 */
export function editShare(param:editShareParam) {
    return post('/user/share/editShare', param);
}

/**
 * [后台]启用配置
 * @notes 
 */
export function deleteShare(param:deleteShareParam) {
    return get('/user/share/deleteShare', param);
}

/**
 * [APP]获取当前设置的分享配置
 * @notes 
 */
export function loadCurrent() {
    return get('/user/share/loadCurrent');
}

/**
 * [后台]新增分享配置
 * @notes 
 */
export function addShare(param:addShareParam) {
    return post('/user/share/addShare', param);
}

