import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface getWalletByUserIdParam {
    userId?:any
}

export interface adjustWalletParam {
    amount?:any
    inOut?:any
    accountType?:any
    message?:any
    userId?:any
}

export interface loadUserWalletsParam {
    amountMax?:any
    current?:any
    size?:any
    searchType?:any
    amountMin?:any
    accountType?:any
    startTime?:any
    endTime?:any
    keyword?:any
    parentId?:any
}
// -------接口列------

/**
 * [APP]获取当前用户的钱包信息
 * @notes 
 */
export function getCurrtUserWallet() {
    return get('/user/wallet/getCurrtUserWallet');
}

/**
 * [后台]加载用户钱包信息
 * @notes 
 */
export function loadUserWallets(param:loadUserWalletsParam) {
    return get('/user/wallet/loadUserWallets', param);
}

/**
 * [后台]调整钱包金额
 * @notes 
 */
export function adjustWallet(param:adjustWalletParam) {
    return post('/user/wallet/adjustWallet', param);
}

/**
 * [后台]获取钱包信息
 * @notes 
 */
export function getWalletByUserId(param:getWalletByUserIdParam) {
    return get('/user/wallet/getWalletByUserId', param);
}

/**
 * [APP]当前用户金币兑换成余额
 * @notes 
 */
export function exchangeCoin() {
    return get('/user/wallet/exchangeCoin');
}

