
import JtPagination from '@/components/JtPagination/index.vue';
import { Component, Vue } from 'vue-property-decorator'
import UserAuthInfo from "./components/UserAuthInfo.vue";
import { getUserInfoByUserId } from "@/api/user/user";
import { loadUserAuthLogs } from "@/api/user/userAuth";
import UserInfo from "@/views/user/userManager/components/UserInfo.vue";

@Component({
  name: 'UserManager',
  components: {
    UserInfo,
    UserAuthInfo,
    JtPagination
  }
})
export default class extends Vue {
  dialogInfoVisible = false
  dialogUserInfoVisible = false
  userInfo: any = {}
  authLogs: any = {}
  listLoading = true
  total = 0
  list = []
  listQuery = {
    userId: this.$route.params.userId,
    current: 1,
    size: 10
  }

  mounted() {
    this.loadUserInfo()
    this.getList()
  }

  //初始加载数据
  handleFilter() {
    this.listQuery.current = 1
    this.getList()
  }

  //加载api数据
  getList() {
    this.listLoading = true
    loadUserAuthLogs(this.listQuery).then(response => {
      this.list = response.data.rows
      this.total = response.data.total
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false
      }, 500)
    }).catch(() => {
      this.listLoading = false
    })
  }
  viewInfo(row: any) {
    this.dialogInfoVisible = true
    this.authLogs = row
  }
  loadUserInfo() {
    getUserInfoByUserId({ userId: this.$route.params.userId }).then(res=>{
      this.userInfo = res.data
    })
  }

  handleUserInfo() {
    this.dialogUserInfoVisible = true
  }
}
