
import { Component,Vue, Watch } from 'vue-property-decorator'
import JtAvatarUpload from '@/components/JtAvatarUpload/index.vue'
import { loadSysAppConfig, updateSysAppConfig } from '@/api/system/sysConfig'

@Component({
    name: 'SwitchSet',
    components: {
        JtAvatarUpload
    }
})
export default class extends Vue {
    formParam:{
        switchVideo:boolean
        switchCoinactivity:boolean
        switchRechange:boolean
        imgCustomer:string
        imgGongzong:string
    } = {
        switchVideo: false,
        switchCoinactivity: false,
        switchRechange: false,
        imgCustomer: "",
        imgGongzong: ""
    }

    mounted(){
        loadSysAppConfig().then(res=>{
            this.formParam = res.data
        })
    }

    handleUpdate(){
        updateSysAppConfig(this.formParam).then(res=>{
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
        });
    }
}
