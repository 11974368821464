
import JtDatePicker from '@/components/JtDatePicker/index.vue';
import JtPagination from '@/components/JtPagination/index.vue';
import { Component, Vue } from 'vue-property-decorator'
import UserAuthOperate from "./components/UserAuthOperate.vue";
import { loadRealnameAuthAudit } from "@/api/user/userAuth";

@Component({
  name: 'UserManager',
  components: {
    JtPagination,
    JtDatePicker,
    UserAuthOperate
  }
})
export default class extends Vue {
  dialogOperateVisible = false
  userAuth: any = {}
  listLoading = true
  total = 0
  list = []
  listQuery = {
    searchType: null,
    keyword: '',
    startTime: '',
    endTime: '',
    current: 1,
    size: 10
  }
  mounted() {
    this.getList()
  }

  //初始加载数据
  handleFilter() {
    this.listQuery.current = 1
    this.getList()
  }

  //加载api数据
  getList() {
    this.listLoading = true
    loadRealnameAuthAudit(this.listQuery).then(response => {
      this.list = response.data.rows
      this.total = response.data.total
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false
      }, 500)
    }).catch(() => {
      this.listLoading = false
    })
  }
  handleAudit(row: any) {
    this.userAuth = row
    this.dialogOperateVisible = true
  }
}
