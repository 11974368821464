
import { Component,Vue } from 'vue-property-decorator'
import JtAvatarUpload from '@/components/JtAvatarUpload/index.vue'
import { loadAdminWebConfig, updateAdminWebConfig } from '@/api/system/sysConfig'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'AdminWebConfig',
    components: {
        JtAvatarUpload
    }
})
export default class extends Vue {

    formParam:{
        needTagsView: boolean
        logoTitle: string
        logoAdress: string
        showLogo: boolean
    } = {
        needTagsView: false,
        logoTitle: '',
        logoAdress: '',
        showLogo: false
    }

    mounted(){
        loadAdminWebConfig().then(res=>{
            this.formParam = res.data
        })
    }

    handleUpdate(){
        updateAdminWebConfig(this.formParam).then(res=>{
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
            AppModule.setSetting(this.formParam)
        });
    }
}
