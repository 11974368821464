
import { loadActivityCoinRoles, loadActivityRoles, loadArticlePrivacy, loadArticleProtocol, loadWithdrawRoles, updateConfig } from '@/api/system/sysConfig'
import JtEditor from '@/components/JtEditor/index.vue'
import { Component,Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'ProtocolRules',
    components: {
        JtEditor
    }
})
export default class extends Vue {

    activeName: string = 'first'

    formParam: {
        columnKey: string
        name: string
        value: string
        desc: string
    } = {
        columnKey: "",
        name: "",
        value: "",
        desc: ""
    }

    mounted(){
        this.loadDataInfo();
    }

    @Watch("activeName")
    loadDataInfo(){
        switch (this.activeName) {
            case 'first':
                loadArticleProtocol().then(res => {
                    this.formParam = res.data
                })
                break;

            case 'second':
                loadArticlePrivacy().then(res => {
                    this.formParam = res.data
                })
                break;

            case 'third':
                loadActivityCoinRoles().then(res => {
                    this.formParam = res.data
                })
                break;

            case 'fourth':
                loadWithdrawRoles().then(res => {
                    this.formParam = res.data
                })
                break;

            case 'five':
                loadActivityRoles().then(res => {
                    this.formParam = res.data
                })
                break;
        
            default:
                loadArticleProtocol().then(res => {
                    this.formParam = res.data
                })
                break;
        }
    }

    handleUpdate(){
        updateConfig(this.formParam).then(() => {
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
        })
    }
}
