
import { addAdminComment } from '@/api/system/reportComment';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'DoComment'
})
export default class DoComment extends Vue {

    @Prop({ required: true, default: false }) dialogCommentVisible!: boolean;
    
    @Prop({ required: true }) readonly reportId!: any;

    formParam = {
      reportId: null,
      comment: null
    }

    get dialogVisible(){
      return this.dialogCommentVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogCommentVisible', val)
    }

    @Emit("getList")
    getList(){}

    handleUpdate(){
        addAdminComment(this.formParam).then(()=>{
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
            this.dialogVisible = false
            this.getList();
        })
    }

    @Watch("dialogCommentVisible")
    formParamInit(){
        this.formParam = {
          reportId: this.reportId,
          comment: null
        }
    }
}
