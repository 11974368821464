
import { Component,Vue } from 'vue-property-decorator'
import JtAvatarUpload from '@/components/JtAvatarUpload/index.vue'
import { loadUserAuthConfig, updateUserAuthConfig } from '@/api/system/sysConfig'

@Component({
  name: 'RealnameAuthConfig',
  components: {
    JtAvatarUpload
  }
})
export default class extends Vue {
  formParam:{
    enable: boolean
    enableWithdraw: boolean
    autoApply: boolean
    sceneId: string
    productCode: string
    certifyId: string
    accessKeyId: string
    secret: string
    endpoint: string
    callbackUrl: string
  } = {
    enable: false,
    enableWithdraw: false,
    autoApply: false,
    sceneId: '',
    productCode: '',
    certifyId: '',
    accessKeyId: '',
    secret: '',
    endpoint: '',
    callbackUrl: ''
  }
  mounted(){
    loadUserAuthConfig().then(res=>{
      this.formParam = res.data
    })
  }
  handleUpdate(){
    updateUserAuthConfig(this.formParam).then(res=>{
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
    });
  }
}
