import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface addAdminCommentParam {
    reportId?:any
    comment?:any
}

export interface loadPageParam {
    current?:any
    reportId?:any
    size?:any
}

export interface removeCommentParam {
    id?:any
}

export interface addUserCommentParam {
    reportId?:any
    comment?:any
}
// -------接口列------

/**
 * [后台]管理员添加评论
 * @notes 
 */
export function addAdminComment(param:addAdminCommentParam) {
    return post('/system/reportComment/addAdminComment', param);
}

/**
 * [后台/APP]查看当前用户反馈信息-评论
 * @notes 
 */
export function loadPage(param:loadPageParam) {
    return get('/system/reportComment/loadPage', param);
}

/**
 * [后台]管理员删除评论
 * @notes 
 */
export function removeComment(param:removeCommentParam) {
    return get('/system/reportComment/removeComment', param);
}

/**
 * [APP]用户添加评论
 * @notes 
 */
export function addUserComment(param:addUserCommentParam) {
    return post('/system/reportComment/addUserComment', param);
}

