
import { saveWithdrawConfig, updateWithdrawConfig } from '@/api/user/withdrawConfig';
import { cloneDeep } from '@/utils/common';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'ChangeWithdrawConfig'
})
export default class ChangeWithdrawConfig extends Vue {
    @Prop({ required: true, default: false }) dialogUpdateVisible!: boolean;
    @Prop({ required: true, default: false }) readonly isEdit!:boolean;
    @Prop({ required: true }) readonly infoParams!: any;
    
    get dialogVisible(){
      return this.dialogUpdateVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogUpdateVisible', val)
    }
     
    formParam:any = {}

    get infoTitle(){
        if(this.isEdit){
            return "编辑配置-【金额：" + this.formParam.amount + "】";
        }else{
            return "添加配置";
        }
    }

    @Emit("getList")
    getList(){}

    handleUpdate(){
        if(this.isEdit){
            updateWithdrawConfig(this.formParam).then(res => {
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.dialogVisible = false
                this.getList();
            })
        }else{
            saveWithdrawConfig(this.formParam).then(res=>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.dialogVisible = false
                this.getList();
            })
        }
    }

    @Watch("infoParams")
    infoParamsChange(){
        this.formParam = cloneDeep(this.infoParams)
    }
}
