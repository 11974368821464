
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { dealRealnameAuthAudit } from "@/api/user/userAuth";

@Component({
  name: 'UserAuthInfo'
})
export default class UserAuthInfo extends Vue {
  @Prop({
    required: true,
    default: false
  }) dialogAuthInfoVisible!: boolean;
  @Prop({ required: true }) readonly userAudit!: any;
  previewList:Array<string> = []
  get dialogVisible() {
    return this.dialogAuthInfoVisible;
  }
  set dialogVisible(val) {
    this.$emit('update:dialogAuthInfoVisible', val)
  }
  @Watch("userAudit")
  infoParamsChange() {
    this.previewList = [this.userAudit.cardForward, this.userAudit.cardBackend, this.userAudit.cardWithFace]
  }
}
