
import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { getPictureOssUploadAccessToken } from "@/api/third/ossManager"
import { hideLoading, showLoading } from '@/utils/loading'

const OSS = require('ali-oss')

@Component({
  name: 'JtFileUpload'
})
export default class JtFileUpload extends Vue {
  @Prop({ required: false }) tips!: string
  ossObj: {
    expiration: string
    securityToken: string
    requestId: string
    accessKeyId: string
    accessKeySecret: string
    assumedRoleUserId: string
    region: string
    regionSuffix: string
    bucket: string
    ossPath: string
    domain: string
  } = {
    expiration: "",
    securityToken: "",
    requestId: "",
    accessKeyId: "",
    accessKeySecret: "",
    assumedRoleUserId: "",
    region: "",
    regionSuffix: "",
    bucket: "",
    ossPath: "",
    domain: ""
  }

  mounted() {
    getPictureOssUploadAccessToken().then(res => {
      this.ossObj = res.data
    })
  }

  @Emit("handleSuccess")
  handleSuccess(result: any) {
  }

  @Emit("handleError")
  handleError() {
  }

  handleUpload(option: any) {
    this.uploadFile(option.file);
  }

  async uploadFile(file: any) {
    const ossConfig = {
      region: this.ossObj.region,
      accessKeyId: this.ossObj.accessKeyId,
      accessKeySecret: this.ossObj.accessKeySecret,
      bucket: this.ossObj.bucket,
      stsToken: this.ossObj.securityToken,
      secure: true
    };
    const ossPath = this.ossObj.ossPath;
    const domain = this.ossObj.domain;
    const client = new OSS(ossConfig);
    try {
      const fileName = Date.now() + file.name.substring(file.name.lastIndexOf("."));
      showLoading({
        message: '上传中',
        className: 'upload-container',
        lazy: 0
      });
      const result = await client.multipartUpload(ossPath + '/' + fileName, file);
      hideLoading();
      if (result && result.res && result.res.requestUrls) {
        result.url = domain + result.name;
        console.log(result)
        this.handleSuccess(result);
      } else {
        this.handleError();
      }
    } catch (e) {
      hideLoading();
      console.error(e);
      this.handleError();
    }
  }
}
