import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface loadWalletDeatailLogsParam {
    amountType?:any
    optType?:any
    current?:any
    inOut?:any
    size?:any
    searchType?:any
    startTime?:any
    endTime?:any
    keyword?:any
}
// -------接口列------

/**
 * [后台]获取账户操作类型
 * @notes 
 */
export function getWalletOptType() {
    return get('/user/walletDetail/getWalletOptType');
}

/**
 * [后台]获取账户操作类型
 * @notes 
 */
export function loadWalletDeatailLogs(param:loadWalletDeatailLogsParam) {
    return get('/user/walletDetail/loadWalletDeatailLogs', param);
}

