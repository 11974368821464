
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { getPictureOssUploadAccessToken } from '@/api/third/ossManager'
import { hideLoading, showLoading } from '@/utils/loading'

type InsertFnType = (url: string, alt: string, href: string) => void
const OSS = require('ali-oss')

@Component({
    name: 'JtEditor',
    components: {
        Toolbar,
        Editor
    }
})
export default class JtEditor extends Vue {

    @Prop({ required: true, default: '' }) html!: string

    set editorHtml(val){
        this.$emit('update:html', val)
    }
    get editorHtml(){
        return this.html
    }

    editor:any = null
    toolbarConfig = {}
    editorConfig:any = {
        placeholder: '请输入内容...',
        MENU_CONF: {
            uploadImage:{
                // 自定义上传
                async customUpload(file: File, insertFn: InsertFnType) {  // TS 语法
                    console.log(11111);
                    getPictureOssUploadAccessToken().then(async res => {
                        let ossConfig = {
                            region: res.data.region,
                            accessKeyId: res.data.accessKeyId,
                            accessKeySecret: res.data.accessKeySecret,
                            bucket: res.data.bucket,
                            stsToken: res.data.securityToken,
                            secure: true
                        };
                        let ossPath = res.data.ossPath;
                        let domain = res.data.domain;
                        const client = new OSS(ossConfig);
                        try {
                            let fileName = Date.now() + file.name.substring(file.name.lastIndexOf("."));
                            showLoading({message: '上传中',className: 'upload-container',lazy:0});
                            const result = await client.multipartUpload(ossPath + '/' + fileName, file);
                            hideLoading();
                            if (result && result.res && result.res.requestUrls) {
                                result.url = domain + result.name;
                                insertFn(result.url, file.name, result.url); 
                            } else {
                                console.error("结果返回异常");
                            }
                        } catch (e) {
                            hideLoading();
                            console.error(e);
                        }
                    });
                }
            }
        }
    }

    onCreated(editor: any) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    }

    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
}
