import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface describeFaceVerifyParam {
    certifyId?:any
}

export interface initFaceVerifyParam {
    realName?:any
    metaInfo?:any
    idCard?:any
}

export interface faceVerifyPersonParam {
    realName?:any
    cardForward?:any
    idCard?:any
    cardWithFace?:any
    cardBackend?:any
}

export interface loadRealnameAuthAuditParam {
    current?:any
    size?:any
    searchType?:any
    startTime?:any
    endTime?:any
    keyword?:any
}

export interface notityParam {
    callbackToken?:any
    certifyId?:any
}

export interface faceVerifyAppealParam {
    orderNo?:any
    cardForward?:any
    cardWithFace?:any
    cardBackend?:any
}

export interface dealRealnameAuthAuditParam {
    notes?:any
    orderNo?:any
    status?:any
}

export interface loadUserAuthLogsParam {
    current?:any
    size?:any
    keyword?:any
    userId?:any
}
// -------接口列------

/**
 * [后台]执行审核-实名
 * @notes 
 */
export function dealRealnameAuthAudit(param:dealRealnameAuthAuditParam) {
    return post('/user/userAuth/dealRealnameAuthAudit', param);
}

/**
 * [APP]阿里云-验证认证结果[true=>成功，false=>失败]
 * @notes 
 */
export function describeFaceVerify(param:describeFaceVerifyParam) {
    return get('/user/userAuth/describeFaceVerify', param);
}

/**
 * [APP]人工-实名认证
 * @notes 
 */
export function faceVerifyPerson(param:faceVerifyPersonParam) {
    return post('/user/userAuth/faceVerifyPerson', param);
}

/**
 * [对外]异步认证通知
 * @notes 
 */
export function notity(param:notityParam) {
    return post('/user/userAuth/notity', param);
}

/**
 * [APP]实名认证状态显示
 * @notes 
 */
export function status() {
    return get('/user/userAuth/status');
}

/**
 * [后台]分页加载实名认证申请
 * @notes 
 */
export function loadRealnameAuthAudit(param:loadRealnameAuthAuditParam) {
    return get('/user/userAuth/loadRealnameAuthAudit', param);
}

/**
 * [APP]页面跳转导航
 * @notes 
 */
export function pageIndex() {
    return get('/user/userAuth/pageIndex');
}

/**
 * [APP]阿里云-实名认证申诉
 * @notes 
 */
export function faceVerifyAppeal(param:faceVerifyAppealParam) {
    return post('/user/userAuth/faceVerifyAppeal', param);
}

/**
 * [后台]实名认证记录
 * @notes 
 */
export function loadUserAuthLogs(param:loadUserAuthLogsParam) {
    return get('/user/userAuth/loadUserAuthLogs', param);
}

/**
 * [APP]阿里云-获取用户实名认证CertifyId
 * @notes 
 */
export function initFaceVerify(param:initFaceVerifyParam) {
    return post('/user/userAuth/initFaceVerify', param);
}

