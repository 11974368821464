
import { Component,Vue } from 'vue-property-decorator'
import JtPagination from '@/components/JtPagination/index.vue'
import { loadEnabled } from '@/api/system/appConfig'
import JtDatePicker from '@/components/JtDatePicker/index.vue'
import { loadReportPage, loadReportType, removeReport } from '@/api/system/report'
import { getUserInfoByUserId } from '@/api/user/user'
import UserInfo from '@/views/user/userManager/components/UserInfo.vue'

@Component({
    name: 'Report',
    components: {
      UserInfo,
      JtDatePicker,
      JtPagination
    }
})
export default class extends Vue {
  listLoading = true
  total = 0
  list = []
  appOptions:Array<{
    appId:any
    platform:any
  }> = []
  reportTypeOptions:Array<{
    key:any
    desc:any
  }> = []
  listQuery = {
    current: 1,
    size: 10,
    appId: null,
    reportType: null,
    status: null,
    keyword: null,
    startTime: null,
    endTime: null
  }
  dialogUserInfoVisible = false
  userInfoParams = {}

  mounted() {
      this.getList()
      this.getAppOptions()
      this.getReportTypeOptions()
  }
  //初始加载数据
  handleFilter() {
    this.getList()
  }

  //获取所有可用应用数据
  getAppOptions(){
    loadEnabled().then(res=>{
        this.appOptions = res.data
    })
  }
  
  //获取所有反馈类型
  getReportTypeOptions(){
    loadReportType().then(res=>{
        this.reportTypeOptions = res.data
    })
  }

  //加载api数据
  getList(){
    this.listLoading = true
    loadReportPage(this.listQuery).then(response => {
        this.list = response.data.rows
        this.total = response.data.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
    })
  }

  handleRemove(row:any){
    this.$confirm('此操作将删除当前反馈, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      removeReport({ id: row.id }).then(response => {
          this.handleFilter()
          this.$message({
              type: 'success',
              message: '操作成功!'
          });
      }).catch(()=>{})
    }).catch(() => {
      this.$message({
            type: 'info',
            message: '已取消当前操作'
      });      
    });
  }

  //编辑
  handleEdit(row:any){
    this.$router.push(`/report/reportComment?reportId=${row.id}`)
  }

  openUserInfo(userId:any){
    getUserInfoByUserId({ userId : userId }).then(res=>{
      this.userInfoParams = res.data
      this.dialogUserInfoVisible = true
    });
  }

}
