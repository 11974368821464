
  import { getWalletOptType, loadWalletDeatailLogs } from '@/api/user/walletDetail';
  import JtDatePicker from '@/components/JtDatePicker/index.vue';
  import JtPagination from '@/components/JtPagination/index.vue';
  import { Component,Vue, Watch } from 'vue-property-decorator'
    
  
  @Component({
      name: 'WalletDetail',
      components: {
        JtPagination,
        JtDatePicker
      }
  })
  export default class WalletDetail extends Vue {
    listLoading = true
    total = 0
    list = []
    optTypes: any = []
    listQuery = {
      searchType: null,
      startTime: null,
      keyword: null,
      inOut: null,
      optType: null,
      endTime: null,
      amountType: null,
      current: 1,
      size: 10
    }
  
    mounted() {
        this.getOptTypes()
        this.getList()
    }
    //初始加载数据
    handleFilter() {
      this.listQuery.current = 1
      this.getList()
    }
  
    //加载api数据
    getList(){
      this.listLoading = true
      loadWalletDeatailLogs(this.listQuery).then(response => {
          this.list = response.data.rows
          this.total = response.data.total
          // Just to simulate the time of the request
          setTimeout(() => {
            this.listLoading = false
          }, 500)
        }).catch(()=>{
          this.listLoading = false
        })
    }
  
    resetSearch(){
      this.listQuery.searchType = null
      this.listQuery.startTime = null
      this.listQuery.keyword = null
      this.listQuery.inOut = null
      this.listQuery.optType = null
      this.listQuery.endTime = null
    }

    getOptTypes(){
      getWalletOptType().then(res => {
        this.optTypes = res.data
      })
    }
  }
  