
import { dealApplys } from '@/api/user/withdrawApply';
import { cloneDeep } from '@/utils/common';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'ExamineApply'
})
export default class ExamineApply extends Vue {
    @Prop({ required: true, default: false }) dialogExamineApplyVisible!: boolean;
    @Prop({ required: true, default: false }) readonly isEdit!: boolean;
    @Prop({ required: true }) readonly infoParams!: any;
    
    get dialogVisible(){
      return this.dialogExamineApplyVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogExamineApplyVisible', val)
    }
    
    formParam:any = {}

    get infoTitle(){
        if(this.isEdit){
            return "提现审核-【" + this.formParam.nickname + "】";
        }else{
            return "提现详情";
        }
    }

    @Emit("getList")
    getList(){}

    handleUpdate(){
      dealApplys(this.formParam).then(res => {
          this.$message({
              type: 'success',
              message: '操作成功!'
          });
          this.dialogVisible = false
          this.getList();
      })
    }

    @Watch("infoParams")
    infoParamsChange(){
      this.formParam = cloneDeep(this.infoParams)
    }
}
