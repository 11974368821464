
import { Component,Vue } from 'vue-property-decorator'
import { loadTestAccountConfig, updateTestAccountConfig } from '@/api/system/sysConfig'

@Component({
    name: 'TestAccount'
})
export default class extends Vue {
    formParam:{
      enable: boolean
      account: string
      msgCode: string
    } = {
      enable: false,
      account: "",
      msgCode: ""
    }

    mounted(){
      loadTestAccountConfig().then(res=>{
          this.formParam = res.data
      })
    }

    handleUpdate(){
      updateTestAccountConfig(this.formParam).then(res=>{
          this.$message({
              type: 'success',
              message: '操作成功!'
          });
      });
    }
}
