
import { loadOneApplyRequest } from '@/api/third/withdrawTaskRequest';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'RequestApply'
})
export default class RequestApply extends Vue {
    @Prop({ required: true, default: false }) dialogRequestApplyVisible!: boolean;
    @Prop({ required: true }) readonly applyNo!: any;
    
    applyRequest:any = {}

    get dialogVisible(){
      return this.dialogRequestApplyVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogRequestApplyVisible', val)
    }

    @Watch("applyNo")
    infoParamsChange(){
        loadOneApplyRequest({ applyNo: this.applyNo }).then(res => {
            this.applyRequest = res.data
        })
    }
}
