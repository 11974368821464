import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface submitWithdrawApplyParam {
    applyId?:any
    withdrawChannel?:any
}

export interface loadUserApplyInfoParam {
    current?:any
    size?:any
    keyword?:any
}

export interface loadApplyInfoParam {
    current?:any
    size?:any
    keyword?:any
}

export interface dealApplysParam {
    applyNo?:any
    dealNotes?:any
    status?:any
}

export interface loadWithdrawApplyDatasParam {
    current?:any
    size?:any
    searchType?:any
    applyNo?:any
    startTime?:any
    dealStatus?:any
    endTime?:any
    keyword?:any
    status?:any
}
// -------接口列------

/**
 * [APP]加载显示提现信息成功条
 * @notes 
 */
export function loadApplyInfo(param:loadApplyInfoParam) {
    return get('/user/withdrawApply/loadApplyInfo', param);
}

/**
 * [后台]加载用户提现申请记录信息
 * @notes 
 */
export function loadWithdrawApplyDatas(param:loadWithdrawApplyDatasParam) {
    return get('/user/withdrawApply/loadWithdrawApplyDatas', param);
}

/**
 * [后台]财务管理-提现管理-处理申请信息
 * @notes 
 */
export function dealApplys(param:dealApplysParam) {
    return post('/user/withdrawApply/dealApplys', param);
}

/**
 * [APP]提交提现申请
 * @notes 
 */
export function submitWithdrawApply(param:submitWithdrawApplyParam) {
    return post('/user/withdrawApply/submitWithdrawApply', param);
}

/**
 * [APP]加载当前用户的提现记录
 * @notes 
 */
export function loadUserApplyInfo(param:loadUserApplyInfoParam) {
    return get('/user/withdrawApply/loadUserApplyInfo', param);
}

