
import { dopublish } from '@/api/system/version';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'Publish'
})
export default class Publish extends Vue {
    @Prop({ required: true, default: false }) dialogUpdateVisible!: boolean;
    @Prop({ required: true }) readonly infoParams!: any;
    
    get dialogVisible(){
      return this.dialogUpdateVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogUpdateVisible', val)
    }
     
    formParam:{
        publishTime: any
        versionId: any
        versionResult: any
    } = {
        publishTime: null,
        versionId: null,
        versionResult: null
    }

    get infoTitle(){
        return "发布当前版本-【" + this.formParam.versionResult + "】";
    }

    @Emit("getList")
    getList(){}

    handlePublish(){
        dopublish(this.formParam).then(() => {
            this.$message.success('操作成功');
            this.dialogVisible = false
            this.getList();
        })
    }

    @Watch("infoParams")
    infoParamsChange(){
        this.formParam.versionId = this.infoParams.id;
        this.formParam.publishTime = this.infoParams.publishTime;
        this.formParam.versionResult = this.infoParams.versionResult;
    }
}
