
import { addVersion, updateVersion } from '@/api/system/version';
import { cloneDeep } from '@/utils/common';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import JtFileUpload from "@/components/JtFileUpload/index.vue";
import { Message } from "element-ui";

@Component({
  name: 'ChangeVersion',
  components: {
    JtFileUpload
  }
})
export default class ChangeVersion extends Vue {
  @Prop({
    required: true,
    default: false
  }) dialogUpdateVisible!: boolean;
  @Prop({
    required: true,
    default: false
  }) readonly isEdit!: boolean;
  @Prop({ required: true }) readonly infoParams!: any;
  @Prop({ required: true })
  readonly appOptions!: Array<{
    appId: any
    platform: any
  }>;

  get dialogVisible() {
    return this.dialogUpdateVisible;
  }

  set dialogVisible(val) {
    this.$emit('update:dialogUpdateVisible', val)
  }

  formParam: {
    id: any
    appId: any
    productName: any
    versionLevel: any
    versionCode: any
    resourcesUrl: any
    resourcesPage: any
    publishTime: string
  } = {
    id: null,
    appId: null,
    productName: '',
    versionLevel: null,
    versionCode: null,
    resourcesUrl: null,
    resourcesPage: null,
    publishTime: ''
  }

  mounted() {
    console.log(this.formParam.productName)
  }

  get infoTitle() {
    if (this.isEdit) {
      return "编辑版本更新配置";
    } else {
      return "添加版本更新配置";
    }
  }

  @Emit("getList")
  getList() {
  }

  fileSuccess(res:any) {
    this.formParam.resourcesUrl = res.url
  }

  fileCheck(){
    if (!this.formParam.versionLevel || !this.formParam.versionCode) {
      Message.error('请先设置大版本及小版本号');
    }
    return this.formParam.versionLevel && this.formParam.versionCode;
  }

  handleUpdate() {
    if (this.isEdit) {
      updateVersion(this.formParam).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.dialogVisible = false
        this.getList();
      })
    } else {
      addVersion(this.formParam).then(res => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.dialogVisible = false
        this.getList();
      })
    }
  }

  @Watch("infoParams")
  infoParamsChange() {
    this.formParam = cloneDeep(this.infoParams)
  }
}
