
import { Component,Vue } from 'vue-property-decorator'
import JtPagination from '@/components/JtPagination/index.vue'
import JtDatePicker from '@/components/JtDatePicker/index.vue'
import { info } from '@/api/system/report'
import { loadPage, removeComment } from '@/api/system/reportComment'
import DoComment from './components/DoComment.vue'
import { getUserInfoByUserId } from '@/api/user/user'
import UserInfo from '@/views/user/userManager/components/UserInfo.vue'

@Component({
    name: 'Report',
    components: {
      UserInfo,
      DoComment,
      JtDatePicker,
      JtPagination
    }
})
export default class extends Vue {
  listLoading = true
  total = 0
  commentsList = []
  listQuery: any = {
    current: 1,
    size: 10,
    reportId: null
  }

  reportInfo: any = {}
  dialogDocommentVisible = false

  userInfoParams: any = {}
  dialogUserInfoVisible = false

  mounted() {
    this.listQuery.reportId = this.$router.currentRoute.query.reportId;
    this.handleFilter()
  }
  //初始加载数据
  handleFilter() {
    this.getList()
    this.getReportInfo()
  }

  //获取所有可用应用数据
  getReportInfo(){
    info({ id : this.listQuery.reportId }).then(res=>{
        this.reportInfo = res.data
    })
  }
  
  //加载api数据
  getList(){
    this.listLoading = true
    loadPage(this.listQuery).then(response => {
        this.commentsList = response.data.rows
        this.total = response.data.total
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
    })
  }

  //添加
  handleDeal(){
    this.dialogDocommentVisible = true
  }

  goBack(){
    this.$router.push('/report/report');
  }

  openUserInfo(userId:any){
    getUserInfoByUserId({ userId : userId }).then(res=>{
      this.userInfoParams = res.data
      this.dialogUserInfoVisible = true
    });
  }

  handleRemove(row:any){
    this.$confirm('此操作将删除当前评论, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      removeComment({ id : row.id }).then(response => {
        this.getList()
        this.$message({
            type: 'success',
            message: '操作成功!'
          });
      }).catch(()=>{})
    }).catch(() => {
      this.$message({
            type: 'info',
            message: '已取消当前操作'
      });      
    });
  }
}
