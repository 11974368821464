
  import { changeStatus, loadWithdrawConfigs, removeWithdrawConfig } from '@/api/user/withdrawConfig'
import { Component,Vue, Watch } from 'vue-property-decorator'
import ChangeWithdrawConfig from './components/ChangeWithdrawConfig.vue'
import JtPagination from '@/components/JtPagination/index.vue'
import { loadWithdrawConfig } from '@/api/system/sysConfig'
import WithdrawConfigSetting from './components/WithdrawConfigSetting.vue'
    
  
  @Component({
      name: 'WithdrawConfig',
      components: {
        ChangeWithdrawConfig,
        JtPagination,
        WithdrawConfigSetting
      }
  })
  export default class extends Vue {
    dialogConfigInfoVisible = false
    isEdit = false
    infoParams = {}
    listLoading = true
    total = 0
    list = []
    listQuery = {
      current: 1,
      size: 10
    }
    withdrawSet: any = {}
    dialogSettingVisible = false
    
  
    mounted() {
        this.getList()
        this.refreshSetting()
    }
    //初始加载数据
    handleFilter() {
      this.listQuery.current = 1
      this.getList()
    }
  
    //加载api数据
    getList(){
      this.listLoading = true
      loadWithdrawConfigs(this.listQuery).then(response => {
          this.list = response.data.rows
          this.total = response.data.total
          // Just to simulate the time of the request
          setTimeout(() => {
            this.listLoading = false
          }, 500)
        }).catch(()=>{
          this.listLoading = false
        })
    }
  
    refreshSetting(){
      loadWithdrawConfig().then(res => {
        this.withdrawSet = res.data
      })
    }

    handleModifyStatus(row:any){
      this.$confirm('此操作将【' + (row.enable ? '禁用' : '启用') + '】当前配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        changeStatus(row).then(response => {
          this.$message({
              type: 'success',
              message: '操作成功!'
            });
          this.getList()
        }).catch(()=>{})
      }).catch(() => {
        this.$message({
              type: 'info',
              message: '已取消当前操作'
            });
      });
    }
  
    handleAddConfig(){
      this.infoParams = {}
      this.dialogConfigInfoVisible = true
      this.isEdit = false
    }

    handleEditConfig(row:any){
      this.infoParams = row
      this.dialogConfigInfoVisible = true
      this.isEdit = true
    }

    handleRemoveConfig(row:any){
      this.$confirm('确定删除当前配置?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeWithdrawConfig({ configId: row.id }).then(res => {
          this.getList()
        })
      }).catch(() => {
        this.$message({
              type: 'info',
              message: '已取消当前操作'
        });      
      });
    }

    handleEditSetting(){
      this.dialogSettingVisible = true
    }
  }
  