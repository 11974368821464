
import { userPage, status } from '@/api/user/user';
import JtDatePicker from '@/components/JtDatePicker/index.vue';
import JtPagination from '@/components/JtPagination/index.vue';
import { Component, Vue, Watch } from 'vue-property-decorator'
import UserInfo from './components/UserInfo.vue';

@Component({
  name: 'UserManager',
  components: {
    JtPagination,
    JtDatePicker,
    UserInfo
  }
})
export default class extends Vue {
  dialogUserInfoVisible = false
  userInfo: any = {}
  listLoading = true
  total = 0
  list = []
  listQuery = {
    searchType: null,
    parentId: null,
    enable: null,
    startTime: '',
    keyword: null,
    endTime: '',
    startOnlineTime: '',
    endOnlineTime: '',
    current: 1,
    size: 10
  }

  mounted() {
    this.getList()
  }

  //初始加载数据
  handleFilter() {
    this.listQuery.current = 1
    this.getList()
  }

  //加载api数据
  getList() {
    this.listLoading = true
    userPage(this.listQuery).then(response => {
      this.list = response.data.rows
      this.total = response.data.total
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false
      }, 500)
    }).catch(() => {
      this.listLoading = false
    })
  }

  handleModifyStatus(row: any) {
    this.$confirm('此操作将【' + (row.enable ? '冻结' : '恢复') + '】当前账户, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      status(row).then(response => {
        const { enable } = response.data
        row.enable = enable
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      }).catch(() => {
      })
    }).catch(() => {
      this.$message({
        type: 'info',
        message: '已取消当前操作'
      });
    });
  }
  resetSearch() {
    this.listQuery.parentId = null
    this.listQuery.enable = null
    this.listQuery.startTime = ''
    this.listQuery.keyword = null
    this.listQuery.endTime = ''
    this.listQuery.startOnlineTime = ''
    this.listQuery.endOnlineTime = ''
  }

  handleUserInfo(row: any) {
    this.userInfo = row
    this.dialogUserInfoVisible = true
  }
}
