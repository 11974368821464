import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface loadOneApplyRequestParam {
    applyNo?:any
}
// -------接口列------

/**
 * [后台]获取某一个提现申请的请求信息
 * @notes 
 */
export function loadOneApplyRequest(param:loadOneApplyRequestParam) {
    return get('/third/withdrawTaskRequest/loadOneApplyRequest', param);
}

