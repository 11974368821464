import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface bindInviteParam {
    code?:any
}

export interface userPageParam {
    current?:any
    size?:any
    searchType?:any
    enable?:any
    startTime?:any
    endTime?:any
    endOnlineTime?:any
    keyword?:any
    parentId?:any
    startOnlineTime?:any
}

export interface updateUserInfoParam {
    sex?:any
    nickname?:any
    birth?:any
    avatar?:any
}

export interface getUserInfoByUserIdParam {
    userId?:any
}

export interface bindPhoneParam {
    code?:any
    phone?:any
}

export interface statusParam {
    id?:any
}
// -------接口列------

/**
 * [APP]修改个人信息
 * @notes 
 */
export function updateUserInfo(param:updateUserInfoParam) {
    return post('/user/user/updateInfo', param);
}

/**
 * [后台]变更账户状态
 * @notes 
 */
export function status(param:statusParam) {
    return get('/user/user/status', param);
}

/**
 * [公共]获取某一用户的信息
 * @notes 
 */
export function getUserInfoByUserId(param:getUserInfoByUserIdParam) {
    return get('/user/user/getUserInfoByUserId', param);
}

/**
 * [APP]绑定邀请人
 * @notes 
 */
export function bindInvite(param:bindInviteParam) {
    return get('/user/user/bindInvite', param);
}

/**
 * [后台]用户管理
 * @notes 
 */
export function userPage(param:userPageParam) {
    return get('/user/user/userPage', param);
}

/**
 * [APP]获取当前登录用户
 * @notes 
 */
export function getCurrentUser() {
    return get('/user/user/curUser');
}

/**
 * [APP]绑定手机号码
 * @notes 
 */
export function bindPhone(param:bindPhoneParam) {
    return post('/user/user/bindPhone', param);
}

