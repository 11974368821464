import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface saveWithdrawConfigParam {
    autoWithdraw?:any
    amount?:any
    applyTimes?:any
    applyTimesUnit?:any
}

export interface removeWithdrawConfigParam {
    configId?:any
}

export interface changeStatusParam {
    id?:any
}

export interface loadWithdrawConfigsParam {
    current?:any
    size?:any
    keyword?:any
}

export interface updateWithdrawConfigParam {
    autoWithdraw?:any
    amount?:any
    id?:any
    applyTimes?:any
    applyTimesUnit?:any
}
// -------接口列------

/**
 * [后台]添加配置
 * @notes 
 */
export function saveWithdrawConfig(param:saveWithdrawConfigParam) {
    return post('/user/withdrawConfig/saveWithdrawConfig', param);
}

/**
 * [后台]获取所有提现配置
 * @notes 
 */
export function loadWithdrawConfigs(param:loadWithdrawConfigsParam) {
    return get('/user/withdrawConfig/loadWithdrawConfigs', param);
}

/**
 * [后台]改变状态
 * @notes 
 */
export function changeStatus(param:changeStatusParam) {
    return get('/user/withdrawConfig/changeStatus', param);
}

/**
 * [后台]删除配置
 * @notes 
 */
export function removeWithdrawConfig(param:removeWithdrawConfigParam) {
    return get('/user/withdrawConfig/removeWithdrawConfig', param);
}

/**
 * [后台]更新保存配置
 * @notes 
 */
export function updateWithdrawConfig(param:updateWithdrawConfigParam) {
    return post('/user/withdrawConfig/updateWithdrawConfig', param);
}

/**
 * [APP]获取APP提现页面数据
 * @notes 
 */
export function loadAppWithdrawData() {
    return get('/user/withdrawConfig/loadAppWithdrawData');
}

